import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Axios from "axios";

const Slider = function (props) {
  let content = [];
  let i = 0;
  let intervalId;
  let timer = 20000;
  const id = props.match.params.id;

  const [src, setSrc] = useState();
  const [showImage, setShowImage] = useState();
  const [showVideo, setShowVideo] = useState();

  async function getArquivosTV(startItem = true) {
    await Axios.get("http://intra.cclind.com.br/api/GetArquivosTV/" + id).then(
      (response) => {
        content = response.data;
        if (startItem) carregarItem();
      }
    );
  }

  function carregarItem() {
    let item = content[i];

    if (content[i].Tipo == "VIDEO") {
      setShowImage(false);
      setShowVideo(true);
      setSrc("http://tvproducao.cclind.com.br/content/" + id + "/" + item.Path);
      clearInterval(intervalId);
      var video = document.getElementById("video");
      video.load();
      video.onended = function () {
        intervalId = setInterval(() => {
          carregarItem();
        }, timer);
      };
    } else {
      setShowImage(true);
      setShowVideo(false);
      setSrc("http://tvproducao.cclind.com.br/content/" + id + "/" + item.Path);
    }

    if (i == content.length - 1) {
      getArquivosTV(false);
      i = 0;
    } else {
      i++;
    }
  }

  useEffect(() => {
    getArquivosTV();
    intervalId = setInterval(() => {
      carregarItem();
    }, timer);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        {showVideo ? (
          <video id="video" autoplay="true" muted="muted">
            <source src={src} />
          </video>
        ) : null}
        {showImage ? <img id="img" src={src} alt="" /> : null}
      </Grid>
    </Grid>
  );
};

export default withRouter(Slider);
